import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';

class Navbar extends Component {
  constructor(props) {
    super(props);
    
  }



  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  toggleRightSidebar() {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }
  render () {
    return (
      <nav className="navbar p-0 fixed-top d-flex flex-row">
        {/* <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo-mini" to="/"><img src={require('../../assets/images/logo-mini.svg')} alt="logo" /></Link>
        </div> */}
        <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
          {/* <button className="navbar-toggler align-self-center" type="button"  onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
            <span className="mdi mdi-menu"></span>
          </button> */}
          
          <ul className="navbar-nav w-100">
            <li className="nav-item w-100">
            
            <img src={require('../../assets/images/slpi.jpg')} height='50vh'  onClick={()=>{window.location.reload(false);}}/>
              
            </li>
          </ul>
          <ul className="navbar-nav navbar-nav-right">
            
            <Dropdown alignRight as="li" className="nav-item">
              <Dropdown.Toggle as="a" className="nav-link cursor-pointer no-caret">
                <div className="navbar-profile">
                  <img className="img-xs rounded-circle" src={require('../../assets/images/admin.png')} alt="profile" />
                  {/* <p className="mb-0 d-none d-sm-block navbar-profile-name"><Trans>Henry Klein</Trans></p> */}
                  {/* <i className="mdi mdi-menu-down d-none d-sm-block"></i> */}
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
              
              <Dropdown.Item href="!#" onClick={evt =>evt.preventDefault()}  className="preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-account-plus text-success"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <div className="preview-subject mb-1" style={{color:'black'}} >
                      <Link to="/user-pages/register-1" onClick={()=>{
                    }}>Add Users</Link></div>
                  </div>
                </Dropdown.Item>
              
                
                <Dropdown.Item href="/" onClick={evt =>evt.preventDefault()}  className="preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-logout text-danger"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <div className="preview-subject mb-1" style={{color:'black'}} >
                      <Link to="/" onClick={()=>{
                        window.localStorage.clear();
                    }}>Log Out</Link></div>
                  </div>
                </Dropdown.Item>

                
               
              </Dropdown.Menu>
            </Dropdown>
          </ul>
          {/* <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
            <span className="mdi mdi-format-line-spacing"></span>
          </button> */}
        </div>
      </nav>
    );
  }
}

export default Navbar;
